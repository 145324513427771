@use '@carbon/styles/scss/config' with (
  // Use flexbox for grid - stick to CSS Grid or Flexbox
  // CSS Grid has become the default grid system in v11
  $use-flexbox-grid: true
);

@use '@carbon/styles';

.white {
  @include styles.theme(styles.$white);
}

.g10 {
  @include styles.theme(styles.$g10);
}

.g90 {
  @include styles.theme(styles.$g90);
}

.g100 {
  @include styles.theme(styles.$g100);
}

// Want to use only one theme? Add the css variables directly in root

// body {
// 	@include styles.theme(styles.$white);
// }

@import '../node_modules/angular-notifier/styles.scss';

.notification-overlay {
  position: fixed !important;
  top: 3rem;
  right: 0;
  margin: auto;
  z-index: 1000;
  width: 28rem;
  transition: all 0.3s ease;
}

@import '@carbon/motion/index';

html.w-mod-touch * {
  background-attachment: scroll !important;
}
.w-block {
  display: block;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.w-clearfix:before,
.w-clearfix:after {
  content: ' ';
  grid-area: 1/1/2/2;
  display: table;
}
.w-clearfix:after {
  clear: both;
}
.w-hidden {
  display: none;
}
.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}
